@import "../../../../sass-variables";

.container {
  padding: 10px 0 0 0;
}

.headerRow {
  justify-content: space-evenly;
  margin: 1rem 0.5rem;
}


.videoHeader {
  width: 800px;
  color: $mainBlue;

  h6 {
    font-style: italic;
    color: $mainBlue;
  }

  p {
    text-align: left;
    color: $darkGrey;
    font-size: 0.9rem;
  }

  a {
    color: $mainBlue !important;
  }

  a:hover {
    font-weight: bold;
  }
}

// this rule is under .videoHeader a so that the styles will cascade appropriately
.backLink {
  display: inline-block;
  margin-bottom: 1rem;
  color: $bgBlue !important;
  font-weight: 600;

  &:hover {
    color: $hoverGreen !important;
    font-weight: normal !important;
  }
}

.title {
  font-size: 22px;
  font-weight: 700;
  line-height: 27.72px;
  color: #000000;
}

.seriesTitle {
  margin-top: 10px;
}

.seriesTitle span:first-child {
  color: #000000;
  font-weight: 600;
  font-size: 20px;
}

.seriesTitle span:nth-child(2) {
  color: #000000;
  font-size: 20px;
  font-weight: initial;
}

.videoContainer {
  position: relative;
  display: block;
  flex-direction: column;
  margin-top: 1rem;
  line-height: 0;
  cursor: pointer;
}

video,
.videoPlaceHolder {
  width: 100%;
  height: 100%;
  display: flex;
  object-fit: cover;
}

audio {
  width: 100%;
  margin-top: 1rem;
}

.videoImages {
  //  position: absolute;
  height: 100%;
  width: 100%;
}

.audioImages {
  width: 800px;
  height: 450px;
}

.videoIcon {
  position: absolute;
  top: 35%;
  left: 43%;
  height: 100px;
  width: 100px;
  z-index: 9;

  svg {
    height: 100%;
    width: 100%;
    color: white;
  }

  span {
    display: inline-block;
    position: relative;
    top: 0;
    right: 35px;
    color: white;
    text-transform: capitalize;
    font-weight: bold;
    font-size: 2rem;
    width: 200px;
    padding-top: 1.2rem;
  }
}

.videoImg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 3;
  object-fit: cover;

}

.imageOverlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 4;
  background:
    linear-gradient(rgba(0, 0, 0, 0.45),
      rgba(0, 0, 0, 0.45)) !important;
}

.videoFinishedOverlay {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  position: absolute;
  /* Changed from relative to absolute */
  left: 0;
  bottom: 15%;
  z-index: 20;

  .overlayButton {
    width: 15vw;
    height: 3vw;
    background-color: #0C2841;
    color: #FFFFFF;
    border-radius: 20px !important;
    font-weight: 600;
    font-size: clamp(.8rem, 1.1vw, 1.5rem);
    line-height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .icon {
    margin-right: 5px;
    margin-bottom: 3px;
  }

  .nextIcon {
    margin-left: 5px;
    margin-bottom: 3px;
  }

  .overlayButton:hover,
  .overlayButton:focus,
  .overlayButton:active {
    background-color: $bgBlue !important;
    /* Keep the background color the same on hover */
    color: white;
    /* Keep text color the same on hover */
    box-shadow: none;
    /* Remove any hover box-shadow */
    text-decoration: none;
    /* Remove any underline on hover */
  }

}

.progressBarContainer {
  display: block;
  //bottom: 0;
  //left: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 15px;
  background-color: #F2F5FC;

  /* The progress bar itself */
  .progressBar {
    height: 100%;
    width: 0;
    background-color: #058282;
  }

}

.videoInfo {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 20px;
  align-items: baseline;
  margin-bottom: 20px;

  span {
    font-size: 18px;
  }

  .videoDetailLeft {
    flex: 10;

    span {
      margin: 0px 5px !important;
      color: #475156 !important;
    }

    .speaker {
      color: #000000 !important;
      font-weight: 600;
      margin-left: 0 !important;

      .speakerLink {
        color: #4B9ACB !important;
        font-weight: initial;
      }
    }

    .dateAndViews {
      display: inline-block;
    }
  }

  .videoDetailRight {
    flex: 2;
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .creditsBadge {
      border-radius: 7px;
      font-size: 1rem;
      padding: .3rem 1rem;
      margin-bottom: 4px;
      border: 1.5px solid #475156 !important;
      color: #475156 !important;
      font-weight: 600 !important;
    }

    .shareButtonContainer {
      margin-left: 1rem;
    }
  }

  .videoDescription {
    font-size: 18px;
    color: #475156;
  }
}


.recommendedLearning {
  display: flex;
  flex-direction: column;
  padding: 1rem;

  .sideButton,
  .sideButton:hover,
  .sideButton:active {
    padding: 0.6rem;
    color: white;
    text-align: center;
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    border-radius: 20px;
    background-color: $brandDarkBlue !important;
    margin-bottom: 1rem;
    margin-top: 0 !important;
    max-height: 50px;

    &:hover {
      background: $brandDarkBlue;
    }
  }

  .disabledClaimButton {
    pointer-events: none;
    background-color: #AEBCC9 !important;
    border-radius: 20px;
    font-weight: 600;
    font-size: 20px;
    color: #ffffff;
    padding: 0.6rem;
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
  }

}

.centeredDiv {
  text-align: center;
  border-width: 2px;
}

.modalTitle {
  text-align: center;
  font-size: x-large;
  font-weight: 600;
  margin-bottom: 20px;
}

.mobileButton {
  background-color: $hoverGreen;
}

.objectivesHeader {
  font: $mainFont;
  background: white;
  color: #475156;
  font-weight: bold;
  font-size: 20px;
  border: none;
  padding: 0px;
  //cursor: pointer;
}

.objectivesList,
.readingList,
.referenceList,
.handoutLink {
  font-size: 15px;
  text-align: left;
  padding-left: 15px;
}

.sideCard {

  border: none;
  border-top: 1px solid #AEBCC9;
  margin-bottom: 1rem;
  border-radius: 0;
  padding-top: 20px;
}

.cardBody {
  padding-left: 0px !important;
  padding-bottom: 0px;
}

.cmeDisclaimer {

  border: none;
  border-top: 1px solid #AEBCC9;
  margin-bottom: 1rem;
  border-radius: 0;
  padding-top: 20px;
  font-size: 15px;

}

// media queries
@media all and (max-width: 1690px) {}

@media all and (max-width: 1280px) {}

@media all and (max-width: 991px) {
  .headerRow {
    justify-content: center;
  }


  .overlayButton {
    width: 25vw;
    height: 5vw;
    background-color: #0C2841;
    color: #FFFFFF;
    border-radius: 20px !important;
    font-weight: 600;
    font-size: clamp(1.2rem, .7vw, 1.5rem);
    line-height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 5px;
  }

  .icon {
    font-size: 1rem;
    min-width: 0 !important;
    min-height: 0 !important;
  }

  .replayIcon {
    font-size: 1rem;
    min-width: 0 !important;
    min-height: 0 !important;
  }

  .nextIcon {
    margin-left: 5px;
    margin-bottom: 3px;
    font-size: 1rem;
    min-width: 0 !important;
    min-height: 0 !important;
  }

}


@media (max-width: 768px) {
  .videoInfo {
    align-items: baseline !important;
    margin-top: 10px;
    margin-bottom: 40px;
    width: 100%;
    margin-left: 0px !important;

    .videoDetailLeft {
      flex: 10;
      display: flex;
      flex-direction: column !important;
      padding: 0px;

      span {
        font-size: clamp(14px, 2.5vw, 18px) !important;
      }

      .dateAndViews {
        span:nth-child(1) {
          margin-left: 0px !important;
        }
      }

      .speaker {
        color: #000000 !important;
        font-weight: 600;
        white-space: no-wrap;

        .speakerLink {
          color: #4B9ACB !important;
          font-weight: initial;
        }
      }
    }

    .videoDetailRight {
      flex: 2;
      align-items: flex-end;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding: 0px;

      .creditsBadge {
        border-radius: 7px;
        padding: .3rem 1rem;
        margin-bottom: 4px;
        border: 1.5px solid #475156 !important;
        color: #475156 !important;
        font-weight: 600 !important;
      }

      .shareButtonContainer {
        margin-left: 1rem;
      }
    }
  }

  .videoDescription {
    font-size: clamp(14px, 2.5vw, 18px) !important;
  }

  .overlayButton {
    height: 6vw;
  }

  .videoImages {
    position: absolute;

    .videoIcon {
      position: absolute;
      /* Positioned relative to .videoWrapper */
      top: 50%;
      /* Center vertically */
      left: 50%;
      /* Center horizontally */
      transform: translate(-50%, -50%);
      /* Adjusts for the actual center of the element */
      z-index: 20;
      height: 75px;
      width: 75px;

      span {
        font-size: 1.5rem;
        /* Ensures the overlay appears above the image */
      }
    }
  }
}


/* For extra small devices (max width 480px) */
@media (max-width: 550px) {

  .overlayButton {
    width: 20vw;
    height: 8vw;
    background-color: #0C2841;
    color: #FFFFFF;
    border-radius: 20px !important;
    font-weight: 600;
    font-size: clamp(2.5vw, .7vw, 1.5rem);
    line-height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 5px;
  }

  .icon {
    font-size: .6rem;
    margin-right: 3px;
    min-width: 0 !important;
    min-height: 0 !important;
  }

  .replayIcon {
    font-size: .8rem;
    min-width: 0 !important;
    min-height: 0 !important;
  }

  .nextIcon {
    margin-left: 5px;
    margin-bottom: 1.5px;
    font-size: .7rem;
    min-width: 0 !important;
    min-height: 0 !important;
  }

  .videoDetailRight {
    flex: 2;
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .creditsBadge {
      font-size: .8rem !important;
      border-radius: 7px;
      padding: .3rem 1rem;
      margin-bottom: 4px;
      border: 1.5px solid #475156 !important;
      color: #475156 !important;
      font-weight: 600 !important;
    }

    .shareButtonContainer {
      margin-left: .5rem !important;

      .shareButtons {
        font-size: .5rem !important;
      }
    }
  }

  .recommendedLearning {

    .sideButton, .disabledClaimButton {
      font-size: .9rem;
    }

  }

}