@import "../../sass-variables.scss";

// hubspot_support_widget.scss

.hs-cta-trigger-button {
    background-color: #5191ae;
    font-weight: bold;
    border-radius: 20px;
    padding: 10px;
    width: 120px;
    position: fixed;
    bottom: 20px;  // Adjust as needed
    right: 20px;   // Adjust as needed
    z-index: 9999; // Ensure it appears above other content
    // Add any additional styles you want here
  }

  .hs-cta-trigger-button:hover{

    background-color: #5191ae !important;
  }

  .hs-cta-trigger-button:active {
    background-color: #417a91 !important; /* Color when clicked */
}