@import "../../../.././sass-variables.scss";

.modalTitle {
  justify-content: center;
  margin: auto;
  text-align: center;
}

.spinnerContainer {
  justify-content: center;
  margin: auto;
  text-align: center;
}

.spinner {
  height: 60px;
  width: 60px;
}
.headerRow {
  justify-content: space-evenly;
  margin: 2rem;
}

.footerDescRow {
  align-items: center;
  height: 250px;
  justify-content: space-evenly;
  flex-direction: column;
  margin: 2rem;
  border: 1px solid black;
}

.footerButtonRow {
  align-items: center;
  margin-top: 30px;
  height: 300px;
  justify-content: space-evenly;
  margin: 2rem;
}

.footerTxt {
  font-size: 1.3rem;
}

.footerButton {
  margin: 0.4rem auto 0.6rem auto;
  padding: 0.6rem;
  width: 120px;
  font-size: 1.1rem;
  text-align: center;
  background-color: $brandBoysenberry !important;
}

.footerButton:active {
  background-color: $brandBoysenberry !important;
}

.footerButton:hover {
  background-color: $brandBoysenberry !important;
}

.seLogo {
  height: 66px;
  width: 200px;
  //margin-bottom: 0;
  z-index: 1000;
  padding: 0;
  margin-left: 40px;
}
.seLogo img {
  display: inline-block;
  height: 100%;
  padding: 5px;
}

.recommendationRow {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.videoHeader {
  width: 800px;
  color: $mainBlue;

  h6 {
    font-style: italic;
    color: $mainBlue;
  }

  p {
    text-align: left;
    color: $darkGrey;
    font-size: 0.9rem;
  }
}

.videoHeader a {

  color: $mainBlue  !important;

}

.videoHeader a:hover {
  font-weight: bold;
}

// this rule is under .videoHeader a so that the styles will cascade appropriately
.backLink {
  display: inline-block;
  margin-bottom: 0.5rem;
  color: $bgBlue  !important;

  &:hover {
    color: $hoverGreen  !important;
    font-weight: normal !important;
  }
}

.feedbackLink {
  display: inline-block;
  color: $bgBlue  !important;
  justify-content: center;

  &:hover {
    color: $hoverGreen  !important;
    font-weight: normal !important;
  }
}

.videoInfo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 30px;
  width: 800px;

  .videoTypeIcon>img,
  .audioTypeIcon>img,
  .pdfTypeIcon>img {
    height: 30px;
    width: 30px;
    padding-top: 0.25rem;
  }

  .videoTypeIcon>img {
    background: $hoverGreen;
  }

  .audioTypeIcon>img {
    background: $mainBlue;
  }

  .pdfTypeIcon>img {
    background: $lightGrey;
  }

  .videoType,
  .videoLesson,
  .videoDuration {
    margin-left: 1rem;
  }

  .videoType,
  .videoLesson {
    margin-right: 1rem;
  }

  .separator {
    margin-right: 1rem;
  }

  .time {
    margin-left: 0.2rem;
  }
}

.videoContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  cursor: pointer;
}

video,
.videoPlaceHolder {
  width: 100%;
  height: 100%;
  display: flex;
  object-fit: cover;
}

audio {
  width: 100%;
  margin-top: 1rem;
}

.videoImages {
  //  position: absolute;
  height: 100%;
  width: 100%;

}

.audioImages {
  width: 800px;
  height: 450px;
}

.videoIcon {
  position: absolute;
  top: 35%;
  left: 43%;
  height: 100px;
  width: 100px;
  z-index: 9;


  svg {
    height: 100%;
    width: 100%;
    color: white;
  }

  span {
    display: inline-block;
    margin-left: -1.8rem;
    color: white;
    text-transform: capitalize;
    font-weight: bold;
    font-size: 2rem;
    width: 174px;
    padding-top: 0.4rem;
  }
}

.videoImg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 3;
  object-fit: cover;

}

.imageOverlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 4;
  background:
    linear-gradient(rgba(0, 0, 0, 0.45),
      rgba(0, 0, 0, 0.45)) !important;
}

.recommendedLearning {
  width: 400px;
  margin-top: 50px;
}

.objectivesHeader {
  font: $mainFont;
  background: white;
  text-transform: uppercase;
  //cursor: pointer;
}

.objectivesList,
.readingList,
.referencesList,
.handoutLink {
  font-size: 1rem;
}

//.objectivesList {
//  list-style: none;
//}

.sideButton {
  //background: $hoverGreen;
  padding: 0.6rem;
  color: white;
  text-align: center;
  width: 100%;
  font-size: 1.2rem;

  margin-top: 5px;
  margin-bottom: 5px;

  span {
    margin-left: 0.5rem;
    text-transform: capitalize;
  }

  svg {
    height: 22px;
    margin-top: -5px;
  }

  &:hover {
    background: lighten($hoverGreen, 5%);
  }
}

.cmeCredit {
  p {
    padding: 0.6rem;
    white-space: pre-wrap;
  }

  .claimButton {
    //  background: $hoverBlue;
    //  color: white;
    //  //text-align: center;
    margin: 0.4rem auto 0.6rem auto;
    padding: 0.6rem;
    width: 100%;
    font-size: 1.2rem;
    text-align: center;
  }

  svg {
    height: 19px;
    margin-top: -5px;
  }
}

.noCredit {
  p {
    padding: 0.6rem;
    white-space: pre-wrap;
  }

  .claimButton {
    //  background: $hoverBlue;
    //  color: white;
    //  //text-align: center;
    margin: 0.4rem auto 0.6rem auto;
    padding: 0.6rem;
    width: 100%;
    font-size: 1.2rem;
    text-align: center;
    background-color: $brandAshGray;
  }

  svg {
    height: 19px;
    margin-top: -5px;
  }
}

.open {
  display: flex;
  justify-content: space-between;
  color: $mainBlue  !important;
  font-size: 1.2rem;

  //hide down chevron
  svg:first-child {
    display: none;
  }

  //show up chevron
  svg:nth-child(2) {
    display: inline-block;
  }
}

.closed {
  display: flex;
  justify-content: space-between;
  color: $hoverGreen;
  font-size: 1.2rem;

  //hide up chevron
  svg:nth-child(2) {
    display: none;
  }

  //show down chevron
  svg:first-child {
    display: inline-block;
  }
}

.recTitle {
  background: $lightGrey;
  margin: 1rem 2rem;
  width: 85%;
  padding: 1rem 0;
  text-align: center;
  //margin-top: 1rem;
  color: $mainBlack;
  font-weight: bold;
}

.deeper {
  margin-top: 2rem;

}

.wider {
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.recsHeader {
  width: 82%;

  h5 {
    color: $darkGrey;
    text-align: left;
    text-transform: uppercase;
    margin-top: 1.5rem;
    margin-bottom: 1.3rem;
  }
}

.recsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.recsContainer {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
}

// media queries
@media all and (max-width: 1690px) {}

@media all and (max-width: 1280px) {}

@media all and (max-width: 980px) {
  .headerRow {
    justify-content: center;
  }

  .videoContainer {
    height: 449px;
    width: auto;
  }
}

@media all and (max-width: 736px) {
  .headerRow {
    justify-content: center;
  }

  .videoContainer {
    height: 250px;
    width: auto;
  }
}

.centeredDiv {
  text-align: center;
  border-width: 2px;
}

.modalTitle {
  text-align: center;
  font-size: x-large;
  font-weight: 600;
  margin-bottom: 20;
}

.mobileButton {
  background-color: $hoverGreen;
}

@media all and (max-width: 480px) {
  .headerRow {
    justify-content: center;
  }

  .videoHeader {
    width: 100%;

  }

  .videoContainer {
    height: 220px;
    width: auto;
    margin-bottom: 5%;
  }


  .videoInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: 30px;
    width: 100%;
    
    .videoType {
      font-size: .9rem;
   
    }

    .videoLesson {
  
      font-size: .9rem;
    }
  
    .videoDuration {
      margin-left: 0;
      font-size: .9rem;
    }

    .separator {
     
    }

    .time {
      font-size: .9rem;
 
    }
  }

  .recsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .videoIcon {
    position: absolute;
    top: 20%;
    left: 35%;
    height: 100px;
    width: 100px;
  }

}