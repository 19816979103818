// --------- variables  ---------

/* main colors */
// $mainBlue: #306996;
$mainBlue: #114a67;
// $bgBlue: #80a1c1;
$bgBlue: #0d6b9a;
$lightBlue: rgb(67,145,193);
// $lightGrey: #efefef;
$lightGrey: #f0f4f7;
// $hoverGreen: rgb(159, 208, 137);
$hoverGreen: #0dc9c9;
// $mediumGrey: #dadada;
$mediumGrey: rgb(175, 189, 201);
$darkGrey: #707980;
$mainBlack: #404549;
// other colors
$barBlue: #4f71be;
$subtitleBlue: #7ba2b9;
$benchmarkYellow: rgb(255, 224, 102);
$noteRed: #ba3f1d;

// new branding colors (07/24/24)
$brandCerulean: #0D6C9A;
$brandChatham: #114A67;
$brandShakespeare: #4B9ACB;
$brandTurquoise: #0DC9C9;
$brandDarkTeal: #058282;
$brandByzantium: #6C286D;
$brandBoysenberry: #962371;
$brandCranberry: #E55D86;
$brandShuttleGray: #5C6269;
$brandAshGray: #AEBCC9;
$brandMercury: #E2E5EC;
$brandDarkBlue: #0C2841;

$barOrange: #de8344;
$barRed: #be614f;
$darkYellow: rgb(255, 186, 8);

$mediumGrey: #dadada;
$hoverBlue: rgba(128, 161, 193, 0.7);
$mainContainerFontColor: #6f8188;
$inputYellow: rgba(255, 224, 102, 0.3);
/* fonts  */
// $mainFont: "Open Sans", sans-serif;
$mainFont: "Outfit", sans-serif;
$secondaryFont: "Raleway", sans-serif;
$thirdFont: 'Source Sans Pro', sans-serif;

//xbox
$custom-xbox-indicator-icon-x:        str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='white' d='M1.41 0l-1.41 1.41.72.72 1.78 1.81-1.78 1.78-.72.69 1.41 1.44.72-.72 1.81-1.81 1.78 1.81.69.72 1.44-1.44-.72-.69-1.81-1.78 1.81-1.81.72-.72-1.44-1.41-.69.72-1.78 1.78-1.81-1.78-.72-.72z'/%3E%3C/svg%3E"), "#", "%23") !default;
