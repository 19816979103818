@import "../sass-variables.scss";

.loginContainer {
  max-width: 400px;
  //position: fixed;
  //top: 50%;
  //left: 50%;
  //margin-top: -40vh;
  //margin-left: -200px;
  margin: 40px auto auto auto;

  //border: 1px solid $mainBlue !important;
}

.logoImage {
  height: auto;
  padding: 0.5rem;
  width: 90%;
  align-self: center;
 
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  padding: 0;
  font-size: 0.8rem;
  a, a:visited, a:hover, a:active {
    color: inherit;
  }
}

.poweredByLogoImage {
  height: 50px;
  width: auto;
  padding: 0.5rem;
}

.ceraveLogoImage {
  width: auto;
  padding: 0.5rem;
  //height: 50px;
  border-bottom: 1px solid $mediumGrey;
}

.imgContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border-bottom: 1px solid $mediumGrey;
  width: 100%;
}

.formText {
  text-align: center;
}

.title {
  text-transform: uppercase;
  color: $mainBlue;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  margin-top: 1rem;
}

p {
  font-size: 1rem;
}

div.instructions {
  font-size: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  text-align: center;
}

h2.instructions {
  font-family: inherit;
  font-weight: bold;
  line-height: 1.1;
  margin-top: 0.5rem;
  margin-bottom: 0;
  text-align: center;
}

.form {
  margin: 1rem;
}

.inputContainer {
  padding-right: 40px;
  padding-left: 40px;
  margin-top: 20px;
  margin-bottom: 5px;
}

input {
  margin-bottom: 0.5rem;
  
}

input:active,
input:focus,
.submitBtn:focus {
  border-color: $bgBlue !important;
  box-shadow: none !important;
}

.submitBtn {
  background: $hoverGreen !important;
  border-color: $hoverGreen !important;
  width: 195px;
  margin-top: 10px;
}

.singleSignOnBtn {
  width: 195px;
  height: 45px;
  margin-top: 10px;
  background: $darkGrey !important;
  border-color: $mainBlack !important;
}

.submitBtn:hover {
  background: lighten($hoverGreen , 5%) !important;
}

.alertMessage {
  padding: 0.5rem;
  margin: 0.5rem 1rem 1rem 1rem;

  justify-content: center;
  text-align: center;
}

.alertMessage p {
  margin: 0;
  padding: 0;
}

.spinner {
  margin-right: 2px;
  margin-bottom: 2px;
}

a.forgotPasswordLink {
  text-align: center;
  color: $brandBoysenberry !important;
  margin: 0.5rem;
}

a.forgotPasswordLink:hover {
  color: lighten($brandBoysenberry  , 5%) !important;
}

div.backToLogin {
  margin-top: 0.8rem;
}

.backToLogin {
  color: $brandBoysenberry  !important;
  margin: 1rem;
}

.backToLogin:hover {
  color: lighten($brandBoysenberry , 5%) !important;
}

.formControl {
  text-align: center;
  //margin: auto;
}

.formCheckbox {
  margin-left: 1.2rem;
  margin-right: 1.2rem;
}

.formDropdown {
  display: flex;
  width: 100%;
  text-align: center;
  margin: auto;
  margin-top: 10px;
}

.formDropdown label {
  margin-top: 0.25rem;
  width: 55%;
  font-size: 0.9rem;
}

.formTextInput {
  width: 70%;
}

.formRow {
  width: 100%;
}

.formTextInput label {}

.formTextInput input {
  text-align: center;
  //margin: auto;
}

.oauthLoginLink {
  width: 220px;
  margin: auto auto 10px auto;

  //border-color: $hoverGreen;
  img {
    height: 35px;
    margin-right: 10px;
  }
}

.ssoContainer {
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 30px;
}

.clickable {
  position:absolute;
  width:100%;
  height:100%;
  top:0;
  left: 0;

  z-index: 1;
}

.button {
  border-style: solid;
  border-width: 0.15rem;
  border-radius: 7px;
  margin-left: 10px;
  height: 40px;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.button:hover {
  background-color: rgb(235,235,235) !important;
}

h3 {
  width: 90%;
  border-top: 1px solid $mediumGrey;
  margin: 0px 20px 0 20px;
}

.registrationHeader {
  align-content: center;
  width: 90%;
  border-top: 0px solid $mediumGrey;
  border-bottom: 1px solid $mediumGrey;
  margin: 1rem auto auto auto;
}

.discount {
  color: red;
}

.cartColumn {
  text-align: left;
  //margin-left: 50px;
}

.billingRow {
  margin: 20px;
  width: 100%;
}

.billingColumn {
  margin: auto;
  text-align: left;
}

.cartRow {
  text-align: center;
}

.tooltip {
  margin-top: 10px;
}

.formControlTextStyle {
  text-align: center;
  font-size: 1rem;
  height: 31px;
}

.buyNowButton {
  display: block;
  margin-left: 33%;
  margin-top: 20px;
  input {
    margin: auto;
  }
}

.transactionFormTextInput {

}