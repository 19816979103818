@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700&display=swap");
@import "~bootstrap";
@import "./sass-variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@400;600&display=swap");


// --------- globals  ---------
*:hover,
*:active,
*:focus,
*:focus-within {
  text-decoration: none !important;
}
a {
  cursor: pointer;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: none !important;
}

html,
body {
  max-width: 100%;
  font-family: $mainFont;
  font-size: 17px;
  color: #475156;
  scroll-behavior: auto;
}

button,
button:active,
button:focus,
button:hover,
.btn,
.btn:active,
.btn:focus,
.btn:hover {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

h1 {
  font-size: 2.125rem;
}
h2 {
  font-size: 1.5rem;
}
h3 {
  font-size: 1.25rem;
}
p {
  font-size: 0.9375rem;
}

a {
  color: $brandShakespeare;
  text-decoration: none;
}
//lighten color for global dropdowns when they are disabled
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: lighten($mainBlack, 45%) !important;
}